.pf-c-form {
  --pf-global--spacer--sm: 0.2rem !important;
  --pf-global--spacer--form-element: 0.2rem !important;
}

.pf-c-form__label-text {
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
}

.pf-c-expandable-section__content {
  margin-top: 0 !important;
}

.pointer {
  cursor: pointer;
}
