@font-face {
    font-family: InterVariable;
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url('./fonts/InterVariable.woff2?v=4.0') format('woff2');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    height: 100%;
}